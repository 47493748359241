import React from "react";
import { Container } from "../../../global";
import {
  ServicesContainer,
  NewContainerBox,
  Flex,
  Title,
  SubTitle,
  Content,
  Img,
  CommonContainer,
  StyledHeaderBg,
} from "./style";
import service1 from "../../../../images/product/service1.png";
import { isSupportWebp } from "../../../../utils";
// import service2 from "../../../../images/product/service2.png";
import Fade from "react-reveal/Fade";
import { graphql, useStaticQuery } from "gatsby";
const ofUsers = () => {
  const data = useStaticQuery(graphql`
    query {
      service1Small: file(sourceInstanceName: { eq: "product" }, name: { eq: "OurServices01Small" }) {
        childImageSharp {
          fluid(maxWidth: 756) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service2Small: file(sourceInstanceName: { eq: "product" }, name: { eq: "OurServices02Small" }) {
        childImageSharp {
          fluid(maxWidth: 536) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service3Small: file(sourceInstanceName: { eq: "product" }, name: { eq: "OurServices03Small" }) {
        childImageSharp {
          fluid(maxWidth: 536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const ourServices01 = "https://files.opayweb.com/file/video/OurServices01.jpg";
  const ourServices02 = "https://files.opayweb.com/file/video/OurServices02.jpg";
  const ourServices03 = "https://files.opayweb.com/file/video/OurServices03.jpg";
  const getImgUrl = url => {
    const isSupportWebpRes = isSupportWebp();
    url = `${url}?x-image-process=image/format,${isSupportWebpRes ? "webp" : "jpeg"}/resize,w_2304,h_918/imageslim`;
    return url;
  };

  return (
    <Container position="relative" style={{ width: "100vw", maxWidth: "100vw", padding: "0" }}>
      <ServicesContainer>
        <Title>Our Services</Title>
        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer width="41.7vw" pl="0">
            <Fade bottom delay={250}>
              <SubTitle color="#FFFFFF !important">Fund your Account, Make Transfers, Pay Bills</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px">
                Live life on your own terms! Add money to your OPay wallet and transfer to other bank accounts for free.
                Enjoy bonuses on airtime & data top-ups and fast bill payments at no extra charge.
              </Content>
            </Fade>
          </CommonContainer>
          <Img style={{ position: "relative" }} mr="85px">
            <img style={{ position: "absolute", zIndex: "1" }} src={getImgUrl(ourServices01)} />
            <StyledHeaderBg objectFit="contain" fluid={data.service1Small.childImageSharp.fluid} />
          </Img>
        </Flex>
        <Flex overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox style={{ background: "#F4FCFA" }}></NewContainerBox>
          <Img objectFit="contain" style={{ "margin-left": "0", position: "relative" }}>
            <img style={{ position: "absolute", zIndex: "1" }} src={getImgUrl(ourServices02)} />
            <StyledHeaderBg objectFit="contain" fluid={data.service2Small.childImageSharp.fluid} />
          </Img>
          <CommonContainer pr="" width="41.7vw" ml="2.3vw">
            <Fade bottom delay={250}>
              <SubTitle color="#1DC99B !important" width="42vw" pl="4.3vw" style={{ letterSpacing: "inherit" }}>
                OPay Debit Cards, <br />
                Your Passport To The World
              </SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="41vw" pl="4.3vw" color="#210F60">
                Get your OPay Debit Card instantly at select OPay agent stores or apply via the OPay app. Enjoy secure,
                fast, and convenient payments with zero maintenance fees for active cards! The OPay Debit Card is
                accepted at any ATM, POS, or online.
              </Content>
            </Fade>
          </CommonContainer>
        </Flex>

        <Flex direction="reverse" overflow="hidden" style={{ paddingLeft: "5.69vw" }}>
          <NewContainerBox></NewContainerBox>
          <CommonContainer pr="80px" width="41.7vw" style={{ "padding-left": "0" }}>
            <Fade bottom delay={250}>
              <SubTitle color="#FFFFFF !important">Owealth</SubTitle>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="16px" width="37.8vw">
                With Owealth, you can get amazing daily interest on your funds while having full access and control of
                the money. Make transactions at any time without affecting your interest!
                <br />
                Powered by BlueRidge Microfinance Bank
              </Content>
            </Fade>
          </CommonContainer>
          <Img objectFit="contain" width="500px" style={{ position: "relative" }}>
            <img style={{ position: "absolute", zIndex: "1" }} src={getImgUrl(ourServices03)} />
            <StyledHeaderBg objectFit="contain" fluid={data.service3Small.childImageSharp.fluid} />
          </Img>
        </Flex>

        {/* <Flex direction="reverse">
          <CommonContainer pl="77px" width="425px">
            <Fade bottom delay={250}>
              <Title>Flexible Savings</Title>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="44px">
                Need a better way to save? Enjoy up to 15% annual interest paid daily when you save with OPay and spend
                without paying withdrawal charges.
              </Content>
              <Content mt="51px" color="#515152">
                Powered by BlueRidge Microfinance Bank.
              </Content>
            </Fade>
          </CommonContainer>
          <Img objectFit="contain" fluid={data.service1.childImageSharp.fluid} />
        </Flex> */}
      </ServicesContainer>
    </Container>
  );
};

export default ofUsers;
